.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.jumbotron {
  color: white;
  background-image: url("../src/content/images/home/ocean-1867285.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  filter: brightness(90%);
  height: 60vh;
    display: flex;              /* establish flex container */
    flex-direction: column;     /* stack flex items vertically */
    justify-content: center;    /* center items vertically, in this case */
    align-items: center;        /* center items horizontally, in this case */
    border: 1px solid black;
}

.btn-primary {
  color: #fff;
  background-color: rgb(137, 98, 151);
  border-color: rgb(137, 98, 151);
  border-top-color: rgb(137, 98, 151);
  border-right-color: rgb(137, 98, 151);
  border-bottom-color: rgb(137, 98, 151);
  border-left-color: rgb(137, 98, 151);
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: rgb(77, 53, 87);
  border-color: rgb(77, 53, 87);
}

.btn-primary:disabled {
  color: #fff;
  background-color: rgb(137, 98, 151);
  border-color: rgb(137, 98, 151);
  border-top-color: rgb(137, 98, 151);
  border-right-color: rgb(137, 98, 151);
  border-bottom-color: rgb(137, 98, 151);
  border-left-color: rgb(137, 98, 151);

}

.bg-primary {
  color: #fff;
  background-color: rgb(137, 98, 151) !important;
}


.blackdiv {
  background-color: black;
  color: white;
  padding: 40px 40px;
}

.whitehr {
  border: 1px solid white;
}

img.rounded-circle {
  width: 237px;
}

div.abouts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

div.about {
  width: 400px;
  text-align: center;
}

div.about img {
  width: 237px;
}

.about h3 {
  margin-top: 5%;
  margin-bottom: 5%;
}

a.round {
  border-radius:50%;
}

body {
  font-family: Georgia, Times, Times New Roman, serif;
  
}

/*.sans {
  font-family: Lato, arial, sans-serif;
}
*/
hr.white {
  border-top: 1px solid white;
}

h2.sans {
  font-size: 1.3rem;
}

.grey {
  color: rgb(164, 164, 164)
}

p, li {
  color: rgb(50, 50, 50)
}

.btn {
  font-weight: 800 !important ;
}


.logo {
  max-height: 100%;
}



/* footer social icons */

.social-network a.icoFacebook:hover {
  background-color: #3B5998;
}

.social-network a.icoFacebook:hover i {
  color: #fff;
}

.social-network a.socialIcon:hover,
.socialHoverClass {
  color: #44BCDD;
}

.social-circle li {
  list-style-type: none;
}

.social-circle li a {
  display: inline-block;
  position: relative;
  margin: 0 auto 0 auto;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  width: 30px;
  height: 30px;
  font-size: 15px;
}

.social-circle li i {
  margin: 0;
  line-height: 30px;
  text-align: center;
}

.social-circle li a:hover i,
.triggeredHover {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -ms--transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.social-circle i {
  color: #595959;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -o-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.social-network a {
  background-color: #F9F9F9;
}

.formCard {
  margin: 4%;
  padding: 3%;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
  background-color: white;
  border: solid black 1px;
  border-radius: 10px;
  box-shadow: 5px 5px black;
  margin-top: 1rem;
  padding: 20px;
}

.form-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-btn {
  width: 25%;
}

.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.spacer {
  padding: 20px;
}

.form-group.family {
  justify-content: center;
  align-items: center;
}

.form-row.family {
  justify-content: center!important;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* for all screens, use 14px font size */
canvas {  
  background-color: white;
  border: 1px solid #CCCCCC;
  width: 50%;
  height: 200px;
  max-width: 100%;   
  align-content: center;

}
/* responsive, form small screens, use full width */
@media (max-width: 800px) {
  canvas {
      width: 90%;
  }
}

.row.form-row{
  display: flex;
  justify-content:left;
  margin-top: 1%;
  width: 100%;
}

.form-row input {
  margin-left:1%;
}

.form-row select {
  margin-left:1%;
}

.error-display {
  color: red;
  margin-left: auto;
  margin-right: 20%;
}

.success-display {
  color: green;
  margin-left: auto;
  margin-right: 20%;
}

.display-toggle {
  border: solid white 1px;
  border-radius: 5px;
  transition: all .1s ease-in-out;
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background-color: rgba(255, 255, 255, 0.15);
}

.display-toggle:hover {
  background-color: rgb(77, 53, 87);
}

button:disabled {
  background-color: #dddddd!important;
}

button:disabled:hover {
  color: red;
}

.col.area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* align-items: flex-start; */
  text-align: left;
  width:80%
}

textarea {
  width: 100%;
}

.row.form-row.area small {
  width: 10%;
  margin-right: 5%;
}

.row.form-row.edit {
  margin-left: 0%;
}

.sigContainer {
  margin: 5% 0 0 0;
}

.sigGroup {
  margin-bottom: 5%;
}

.col .error-display {
  margin: auto
}

.sigImgWrapper {
  border: 1px solid black;
  width: 80%;
  margin: auto;
}

.sigImage {
  width:100%;
  object-fit: contain;
}

.container.thankyou {
  margin-top: 10%;
  margin-bottom: 10%;
}

.nacheckbox {
    display: flex;
    align-content: center;
    text-align: justify;
    margin-left: 1%;
    
}

.catalog {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.urn-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.urn, .keepsake {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  flex: 0 0 calc(33.333333% - 2em);
  margin: 1em;
  flex-grow: 3;
}

.urn-name, .keepsake-name {
  text-align: center;
  padding-bottom: 0px!important;
}

.urn-card, .keepsake-card, .service-card {
  display: block;
  max-width: 100%;
  max-height: 169px;
  -o-object-fit: contain;
  object-fit: contain;
  transition: all 0.3s;
  cursor: default;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,0.125);
  border-radius: 0.25rem;
  width: 1500px;
}

.price, .keepsake-price {
  font-size: 14px;
  text-align: center;
}

.urns-dropdown {
  width: 100%;
  display: flex;
  padding: 15px;
  border: 1px solid rgba(0,0,0,0.125);
  border-radius: 0.25rem;
}
.urns-dropdown:hover {
  cursor: pointer;
  background-color: #e6e6e6;
}

.loader {
  display: inline-block;
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid rgb(137, 98, 151); /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.navbar-brand {
  max-width: 300px;
  width: 50%;
}